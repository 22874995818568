import React from 'react'
import styled from 'styled-components'
import Seo from '../components/seo'
import Reset from '../components/reset'
import Navbar from '../components/navbar'
import SubFooter from '../components/subfooter'
import Footer from '../components/footer'
import { Container, Section, H2, Separator } from '../components/style'
import { GatsbyImage } from 'gatsby-plugin-image'
import { graphql } from 'gatsby'
import { Youtube } from 'react-feather'
import { useIntl } from 'gatsby-plugin-intl'

const StyledSection = styled(Section)`
  background-color: #f9f9f9;
`

const StyledSeparator = styled(Separator)`
  margin: 0.5rem 4.5rem 1.5rem;
`

const RealizationsList = styled.div`
  display: flex;
  flex-direction: column;
`

const Realization = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #fff;
  margin-bottom: 4rem;

  @media (min-width: 800px) {
    flex-direction: row;
  }
`

const Left = styled.div`
  flex-basis: 70%;
  padding: 2rem 2.5rem;
`

const Right = styled.div`
  display: flex;
  flex-direction: column;
  flex-basis: 30%;
`

const H5 = styled.h5`
  font-size: 1.1rem;
  padding-bottom: 1rem;
`

const Paragraph = styled.p`
  text-align: justify;
  font-size: 1rem;
  font-weight: 100;
  line-height: 22px;
  letter-spacing: 0.1rem;
  
  ul {
    list-style: inside;
    padding-top: 1rem;
  }
`
const YoutubeLink = styled.a`
  display: flex;
  flex-direction: row;
  font-size: 1rem;
  font-weight: 100;
  align-self: center;
  padding: 1rem;
  text-decoration: none;
  color: #000;

  p {
    align-self: center;
    padding: 0 0.5rem;
  }
`

const RealizationsPage = (props) => {
  const intl = useIntl()
  const locale = intl.locale !== 'pl' ? `${intl.locale}` : 'pl'

  return (
    <React.Fragment>
      <Seo
        title={intl.formatMessage({ id: 'site_title' })}
        description={intl.formatMessage({ id: 'site_description' })}
        keywords={intl.formatMessage({ id: 'site_keywords' })}
        lang={locale}
      />
      <Reset />
      <Navbar activepage="realizations" />
      <main>
        <StyledSection>
          <Container>
            <H2>{intl.formatMessage({ id: 'page_realizations_title' })}</H2>
            <StyledSeparator />
            <RealizationsList>
              {
                props.data.allMarkdownRemark.edges.map(edge => (
                  <Realization key={edge.node.id}>
                    <Left>
                      <H5>{edge.node.frontmatter.title}</H5>
                      <Paragraph dangerouslySetInnerHTML={{ __html: edge.node.html}}></Paragraph>
                    </Left>
                    <Right>
                      <GatsbyImage image={edge.node.frontmatter.image.childImageSharp.gatsbyImageData} alt="Test"></GatsbyImage>
                      <YoutubeLink
                        id="yt"
                        target="_blank"
                        rel="noopener noreferrer"
                        href={edge.node.frontmatter.youtube_link}
                      >
                        <p>Obejrzyj w serwisie YouTube</p>
                        <Youtube color="#000" size="30" />
                      </YoutubeLink>
                    </Right>
                  </Realization>
                ))
              }
            </RealizationsList>
          </Container>
        </StyledSection>
      </main>
      <SubFooter />
      <Footer />
    </React.Fragment>
  )
}

export default RealizationsPage

export const pageQuery = graphql`
  query RealizationsQuery {
    allMarkdownRemark(sort: {order: ASC, fields: fileAbsolutePath}) {
      edges {
        node {
          id
          frontmatter {
            title
            youtube_link
            image {
              childImageSharp {
                gatsbyImageData(
                  placeholder: BLURRED
                  transformOptions: {fit: COVER}
                  layout: FULL_WIDTH
                )
              }
            }
          }
          html
        }
      }
    }
  }
`
